import ApiService from '../../services/api.service'
import { AuthService } from '../../services/auth.service'
import TokenService from '../../services/token.service'
import router from '../../router'
import store from '../../store'

export default {
  state: {
    authToken: null,
    isUserLoggedIn: false,
    userData: null
  },
  getters: {
    authToken: state => state.authToken,
    isUserLoggedIn: state => state.isUserLoggedIn,
    userData: state => state.userData
  },
  mutations: {
    setAuthToken (state, token) {
      state.authToken = token
    },
    setUserLoggedIn (state, payload) {
      state.isUserLoggedIn = payload
    },
    setUserData( state, payload) {
      state.userData = payload
    }
  },
  actions: {
    async login (_, payload) {
      AuthService.login(payload.email, payload.password)
    },
    async logout ({ commit }) {
      await AuthService.logout()
      localStorage.clear()
      TokenService.removeToken()
      TokenService.removeRefreshToken()
      ApiService.removeHeader()
      commit('setUserLoggedIn', false)
      router.push('/login')
    },
    async setUser({ commit }, payload) {
      commit('setUserData', payload)
    },
    async lockScreenLogout ({ commit }) {
      await AuthService.logout()

      localStorage.clear()
      TokenService.removeToken()
      TokenService.removeRefreshToken()
      ApiService.removeHeader()
      commit('setUserLoggedIn', false)
      router.push('/user/forgot-password')
    },
    async validateToken ({ dispatch }, payload) {
      let refreshToken = TokenService.getRefreshToken()
      let accessToken = TokenService.getToken()
      let organisations = JSON.parse(localStorage.getItem('organisations'))
      let user_data = JSON.parse(localStorage.getItem('user_data'))
      let userOrg = JSON.parse(localStorage.getItem('userOrg'))
      if (refreshToken && accessToken) {
        let authData = {
          access_token: accessToken,
          refresh_token: refreshToken,
          organisations: organisations,
          user_data: user_data,
          userOrg: userOrg,
          onReload: true,
          fullPath: payload
        }
        dispatch('loginSuccess', authData)
      } else {
        router.push('/login')
      }
    },
    async loginSuccess ({ commit, dispatch }, payload) {
      commit('setAuthToken', payload.access_token)
      commit('setUserLoggedIn', true)
      localStorage.setItem('user_data', JSON.stringify(payload.user_data))
      localStorage.setItem("organisations", JSON.stringify(payload.organisations))
      commit('setUserData', JSON.parse(localStorage.getItem('user_data')))
      store.dispatch('setOrgs', JSON.parse(localStorage.getItem('organisations')))
      TokenService.saveToken(payload.access_token)
      if (payload.refresh_token) {
        TokenService.saveRefreshToken(payload.refresh_token)
      }
      ApiService.setHeader(payload.access_token)
      if (payload.onReload) {
        if(payload.userOrg) {
          dispatch('setUserOrg', payload.userOrg)
          router.push(payload.fullPath)
        } else if (payload.fullPath === '/org') {
          router.push('/org')
        } else {
          router.push('/login')
        }
      } else {
        router.push('/org')
      }
    },
    // eslint-disable-next-line no-unused-vars
    async orgLoginSucess ({ commit }, payload) {
      ApiService.mountInterceptor()
      commit('setUserLoggedIn', true)
      router.push('/dashboards')
    },
    async loginFailed ({ commit }) {
      localStorage.clear()
      sessionStorage.clear()
      TokenService.removeToken()
      TokenService.removeRefreshToken()
      ApiService.removeHeader()
      commit('setUserLoggedIn', false)
    },
    async tokenExpired ({ commit }) {
      localStorage.clear()
      sessionStorage.clear()
      TokenService.removeToken()
      TokenService.removeRefreshToken()
      ApiService.removeHeader()
      commit('setUserLoggedIn', false)
      router.push('/login')
    }
  }
}
