import ApiService from './api.service'
import TokenService from './token.service'
import store from '../store'
import * as toastr from 'toastr'
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });

class AuthenticationError extends Error {
  constructor (errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

//ApiService.init("https://backend.vflowtechiot.com/cockpit")
ApiService.init("https://backend.thingsbeam.com/cockpit")
const AuthService = {
  /**
     * Login the user and use auth token to org login.
     *
     * @returns access_token
     * @throws AuthenticationError
    **/
  login: async function (email, password) {
    const requestData = {
      method: 'post',
      url: '/api/v1/sign-in',
      data: {
        email: email,
        password: password
      }
    }
    try {
      const response = await ApiService.customRequest(requestData)
      store.dispatch('loginSuccess', response.data)
      toaster.success('Login Successfully');
    } catch (error) {
      store.dispatch('loginFailed')
      if (error.request) {
        let errorResponse = JSON.parse(error.request.response)
        toaster.error(errorResponse.errors.reason);
      } else {
        toaster.error(error.title.errors.reason);
      }
    }
  },

  /**
     * Refresh the access token.
    **/
  refreshToken: async function () {
    const refreshToken = TokenService.getRefreshToken()

    const requestData = {
      method: 'post',
      url: '/refresh',
      data: {
        refresh_token: refreshToken
      }
    }

    try {
      const response = await ApiService.customRequest(requestData)

      TokenService.saveToken(response.data.access_token)
      TokenService.saveRefreshToken(response.data.refresh_token)
      // Update the header in ApiService
      ApiService.setHeader()

      return response.data.access_token
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.detail)
    }
  },

  /**
     * Logout the current user by removing the token from storage.
     *
     * Will also remove `Authorization Bearer <token>` header from future requests.
    **/
  logout: async function () {
    // // let loader = Vue.$loading.show({
    // //   loader: 'bars',
    // //   color: '#ffffff',
    // //   backgroundColor: '#000000'
    // // })
    const accessToken = TokenService.getToken()
    const refreshToken = TokenService.getRefreshToken()

    const requestData = {
      method: 'post',
      url: '/api/v1//sign-out',
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {
        access_token: accessToken,
        refresh_token: refreshToken
      }
    }

    try {
      const response = await ApiService.customRequest(requestData)
      // loader.hide()
      return response.data
    } catch (error) {
      // loader.hide()
      throw new AuthenticationError(error.response.status, error.response.data.detail)
    }
  },

  validateAccessToken: async function (refreshToken, accessToken) {
    const requestData = {
      method: 'post',
      url: '/api/v1/validate-token',
      headers: { Authorization: `Bearer ${refreshToken}` },
      data: {
        access_token: accessToken
      }
    }

    try {
      const response = await ApiService.customRequest(requestData)
      store.dispatch('orgLoginSucess', response.data)
    } catch (error) {
      store.dispatch('tokenExpired')
    }
  },

  /**
     * Login the org and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
    **/
  orgLogin: async function (config) {
    // let loader = Vue.$loading.show({
    //   loader: 'bars',
    //   color: '#ffffff',
    //   backgroundColor: '#000000'
    // })

    const requestData = {
      method: 'post',
      url: `/orgs/${config.id}/sign-in`,
      headers: { 'Auth-Token': config.authToken },
      data: {}
    }

    try {
      const response = await ApiService.customRequest(requestData)
      store.dispatch('orgLoginSucess', response.data)
      // loader.hide()
    } catch (error) {
      store.dispatch('loginFailed')
      // loader.hide()
      let errorResponse = JSON.parse(error.request.response)
      toastr.error(errorResponse.title, 'Error')
        console.log('org Login fail ', errorResponse)
    }
  },

  registerUser: async function (user) {
    const requestData = {
      method: 'post',
      url: '/api/v1/sign-up',
      data: user
    }

    const response = await ApiService.customRequest(requestData)
    return response.data
  },

  validateOrgUrl: async function (url) {
    const requestData = {
      method: 'post',
      url: 'validate_org_url',
      data: {
        url: url
      }
    }

    const response = await ApiService.customRequest(requestData)
    return response.data
  },

  forgotPassword: async function(email) {
    const requestData = {
      method: 'post',
      url: '/api/v1/forgot-password',
      data: email
    }
    const response = await ApiService.customRequest(requestData)
    return response.data
  },
  setUser: async function () {
    const refreshToken = TokenService.getRefreshToken()
    const requestData = {
      method: 'get',
      url: '/api/v1/user/show',
      headers: { Authorization: `Bearer ${refreshToken}` }
    }

    try {
      const response = await ApiService.customRequest(requestData)
      store.dispatch('setUser', response.data)
      store.dispatch('setOrgs', response.data.organisations)
      store.dispatch('setUserOrg', response.data.organisations[0])
      store.dispatch('setUserProfile', response.data.organisations[0].id)
    } catch (error) {
      store.dispatch('tokenExpired')
    }
  },
}

export default AuthService

export { AuthService, AuthenticationError }
