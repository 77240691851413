import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store/index.js'
import 'bootstrap/dist/js/bootstrap.bundle'
import './assets/scss/app.scss'
import VueFeather from 'vue-feather';
import Breadcrumbs from './components/bread_crumbs.vue';
import cloudTemplate from 'cloud-test-one'
import 'cloud-test-one/dist/cloud-test-one.css'
// import cloudTemplate from '../node_modules/cloud-test-one/src/index'
import { apiUrl} from './constants/config'
import ApiService from './services/api.service';
import VueMultiselect  from 'vue-multiselect'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import * as Sentry from "@sentry/vue";
import Toaster from '@meforma/vue-toaster';
import LogRocket from 'logrocket';
LogRocket.init('810ayv/chart');

ApiService.init(apiUrl)
const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://b22fa698c6a24bb99681b3a94c99ae52@o4505362450546688.ingest.sentry.io/4505587699089408",
    integrations: [
        new Sentry.BrowserTracing({
            logErrors: true,
            // release: __SENTRY_RELEASE__,
            release: `"${process.env.npm_package_name}@${process.env.npm_package_version}"`,
            environment: process.env.NODE_ENV,
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        // If replay of what user clicked is required
        // new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Set sampling rate for profiling - this is relative to tracesSampleRate
    profilesSampleRate: 1.0,
    // Session Replay
    //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router)
app.use(store)
app.use(Toaster, {position: "top-right"})
app.use(cloudTemplate)
app.component(VueFeather.name, VueFeather)
app.component('Breadcrumbs', Breadcrumbs)
app.use(setupCalendar, {})
app.component('multiselect', VueMultiselect )
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)
app.mount('#app')
