/* eslint-disable no-unused-vars */
import _ from 'lodash'

export default {
  state: {
    apps: [],
    orgs: [],
    userOrg: null,
  },
  getters: {
    orgApps: state => state.apps,
    orgs: state => state.orgs,
    userOrg: state => state.userOrg
  },
  mutations: {
    setOrgApps (state, apps) {
      state.apps = apps
    },
    setOrgs (state, orgs) {
      state.orgs = orgs
    },
    setUserOrg (state, org) {
      state.userOrg = org
    }
  },
  actions: {
    async setOrgApps ({ commit, _ }, payload) {
      commit('setOrgApps', payload)
    },
    async setOrgs ({ commit, _ }, payload) {
      commit('setOrgs', payload)
    },
    async setUserOrg ({ commit, _ }, payload) {
      localStorage.setItem('userOrg', JSON.stringify(payload))
      commit('setUserOrg', payload)
    }
  }
}
