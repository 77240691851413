<template>
  <customizer
    @close-sidebar="closeSidebar"
    @on-cancel="closeSidebar"
    @on-save="onSave"
    :openSidebar="true"
    :applyText="'Apply Changes'"
    :discardText="'Discard'"
    headerTitle="Realtime Historical Settings"
  >
    <template v-slot:content>
      <div>
        <div class="setting-options-group mb-3">
          <div
            class="option"
            :class="{ selected: selectedSettings === 'realtime' }"
            @click="selectSettings('realtime')"
          >
            Realtime<sup v-if="openSection == 'realtime'"
              ><span class="dot"></span
            ></sup>
          </div>
          <div
            class="option"
            :class="{ selected: selectedSettings === 'historical' }"
            @click="selectSettings('historical')"
          >
            History<sup v-if="openSection == 'historical'"
              ><span class="dot"></span
            ></sup>
          </div>
        </div>
        <div class="main-content" v-show="selectedSettings === 'realtime'">
          <form>
            <div class="input-group mb-3">
              <label class="form-label">Last</label>
              <multiselect
                v-model="selectedRealtimeGrouping"
                @select="onSelectRealtimeGrouping"
                :options="realtimeGroupingData"
                :select-label="''"
                :selected-label="''"
                :deselect-label="''"
                label="name"
                track-by="key"
                :allow-empty="false"
              ></multiselect>
            </div>
            <div class="input-group mb-3">
              <label class="form-label">Data aggregation function</label>
              <multiselect
                v-model="selectedAggFunction"
                :options="aggregationFunctions"
                :select-label="''"
                :selected-label="''"
                :deselect-label="''"
                :allow-empty="false"
              ></multiselect>
            </div>
            <div class="input-group mb-3">
              <label class="form-label">{{selectedAggFunction === 'no' ? 'Refresh Rate' : 'Grouping interval'}}</label>
              <multiselect
                v-model="selectedRealtimeInterval"
                :options="groupInterval"
                :select-label="''"
                :selected-label="''"
                :deselect-label="''"
                label="name"
                track-by="key"
                :allow-empty="false"
              ></multiselect>
            </div>
          </form>
        </div>
        <div class="main-content" v-show="selectedSettings === 'historical'">
          <form>
            <div class="historical-type d-flex justify-content-around">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"
                  v-model="historicalSettingType"
                  name="historicalSettingType"
                  value="relative"
                >
                <label class="form-check-label">
                  Relative
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"
                  v-model="historicalSettingType"
                  name="historicalSettingType"
                  value="absolute"  
                >
                <label class="form-check-label">
                  Absolute
                </label>
              </div>
            </div>
            <div v-if="historicalSettingType === 'absolute'" class="range-wrap">
              <div class="input-group mb-3">
                <label class="form-label">From</label>
                <VueDatePicker v-model="range.start" :enable-time-picker="false" position="left" />
              </div>
              <div class="input-group mb-3">
                <label class="form-label">To</label>
                <VueDatePicker v-model="range.end" :enable-time-picker="false" />
              </div>

            </div>
            <div class="input-group mb-3"
              v-if="historicalSettingType === 'relative'"
            >
            <label class="form-label">Last</label>
              <multiselect
                v-model="selectedHistoricalGrouping"
                @select="onSelectHistoricalGrouping"
                :options="historicalGroupingData"
                :select-label="''"
                :selected-label="''"
                :deselect-label="''"
                label="name"
                track-by="key"
                :allow-empty="false"
              ></multiselect>
            </div>
            <div class="input-group mb-3">
              <label class="form-label">Data aggregation function</label>
              <multiselect
                v-model="selectedAggFunction"
                :options="aggregationFunctions"
                :select-label="''"
                :selected-label="''"
                :deselect-label="''"
                :allow-empty="false"
              ></multiselect>
            </div>
            <div
              v-if="selectedAggFunction !== 'no'"
            >
            <label class="form-label">Grouping interval</label>
              <multiselect
                v-if="!isConsumptionBarChart"
                v-model="selectedHistoricalInterval"
                :options="groupIntervalHistorical"
                :select-label="''"
                :selected-label="''"
                :deselect-label="''"
                label="name"
                track-by="key"
                :allow-empty="false"
              ></multiselect>
              <multiselect
                v-if="isConsumptionBarChart"
                v-model="selectedHistoricalIntervalConsumptionTable"
                :options="[{ name: 'no', key: 'no', value: 0, type: 'noo' }]"
                :select-label="''"
                :selected-label="''"
                :deselect-label="''"
                label="name"
                track-by="key"
                :allow-empty="false"
              ></multiselect>
            </div>
          </form>
        </div>
      </div>
    </template>
  </customizer>
</template>

<script>
import groupingData from './groupingData'
import customizer from '../components/customizer/index.vue'
import moment from 'moment'
import _ from 'lodash'
import { ref } from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {
    customizer,
    VueDatePicker
  },
  inject: {
    updateDashboardPanelFn: {
      default: () => {}
    },
    loadDashboardPanelFn: {
      default: () => {}
    },
    loadExportedDashboardPanelFn: {
      default: () => {}
    }
  },
  props: {
    dashboardType: {
      type: String
    },
    selectedDashboard: {
      type: Object,
      required: true
    },
    selectedPanel: {
      type: Object,
      required: true
    },
    layoutType: {
      type: String
    },
    widgetCategory: {
      type: Array
    },
    widgetData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      picked: ref(new Date()),
      selectedTab: 'realtime',
      realtimeGroupingData: [],
      historicalGroupingData: [],
      selectedRealtimeGrouping: {},
      selectedHistoricalGrouping: {},
      selectedRealtimeInterval: {},
      selectedHistoricalInterval: {},
      selectedAggFunction: '',
      historicalSettingType: 'relative',
      range: {
        start: null,
        end: null
      },
      selectedSettings: 'realtime',
      groupInterval: [],
      groupIntervalHistorical: [],
      widgetId: null,
      entityType: 'panel',
      selectedHistoricalIntervalConsumptionTable: { name: 'no', key: 'no', value: 0, type: 'noo' },
      aggregationFunctions: ['no', 'min', 'max', 'average', 'sum', 'count', 'max-min'],
      withNoAgg: ['no', 'min', 'max', 'average', 'sum', 'count', 'max-min'],
      withoutNoAgg: ['min', 'max', 'average', 'sum', 'count', 'max-min']
    }
  },
  
  methods: {
    closeSidebar () {
      this.$emit('close-right-panel')
    },
    selectSettings (setting) {
      this.selectedSettings = setting
    },
    setArrowType (iconId) {
      this.arrowType = iconId
      this.selectedSettings = iconId.includes('History')
        ? 'historical'
        : 'realtime'
    },

    // Realtime historical setting functions
    setCustomGroupingInterval () {
      let endDate = moment(this.range.end)
      let startDate = moment(this.range.start)

      let diffDays = endDate.diff(startDate, 'days')
      let diffMonths = endDate.diff(startDate, 'months')
      let diffYears = endDate.diff(startDate, 'years')

      if (diffYears >= 1) {
        this.selectedHistoricalGrouping = this.historicalGroupingData[21]
        this.selectedHistoricalInterval = this.selectedHistoricalGrouping.groupingIntervals[0]
      } else if (diffMonths >= 6) {
        this.selectedHistoricalGrouping = this.historicalGroupingData[20]
        this.selectedHistoricalInterval = this.selectedHistoricalGrouping.groupingIntervals[0]
      } else if (diffMonths >= 3) {
        this.selectedHistoricalGrouping = this.historicalGroupingData[19]
        this.selectedHistoricalInterval = this.selectedHistoricalGrouping.groupingIntervals[0]
      } else if (diffDays >= 30) {
        this.selectedHistoricalGrouping = this.historicalGroupingData[18]
        this.selectedHistoricalInterval = this.selectedHistoricalGrouping.groupingIntervals[0]
      } else if (diffDays >= 7) {
        this.selectedHistoricalGrouping = this.historicalGroupingData[17]
        this.selectedHistoricalInterval = this.selectedHistoricalGrouping.groupingIntervals[0]
      } else if (diffDays >= 1) {
        this.selectedHistoricalGrouping = this.historicalGroupingData[16]
        this.selectedHistoricalInterval = this.selectedHistoricalGrouping.groupingIntervals[0]
      }
    },
    open (params = {}) {
      let filterMetadata = {}
      if (this.entityType === 'widget' && !_.isEmpty(params)) {
        filterMetadata = params
      } else {
        if (this.dashboardType === 'viewable') {
          let exportedDashboardKey =
            'exported_dashboard_' +
            this.selectedDashboard.uuid +
            '_filter_metadata'
          let exportedDashboardFilterMetadata = localStorage.getItem(
            exportedDashboardKey
          )
          filterMetadata = exportedDashboardFilterMetadata
            ? JSON.parse(exportedDashboardFilterMetadata)
            : this.selectedPanel.filter_metadata
        } else {
          filterMetadata = this.selectedPanel.filter_metadata
        }
      }

      let lastKey = filterMetadata.last
      let intervalKey =
        filterMetadata.group_interval + '_' + filterMetadata.group_interval_type
      let startDate = filterMetadata.from_date
      let endDate = filterMetadata.to_date
      if (filterMetadata.type === 'realtime') {
        this.selectedRealtimeGrouping = _.find(
          this.realtimeGroupingData,
          (grouping) => {
            return grouping.key === lastKey
          }
        )
        this.selectedRealtimeInterval = _.find(
          this.selectedRealtimeGrouping.groupingIntervals,
          (interval) => {
            return interval.key === intervalKey
          }
        )
        this.groupInterval = this.selectedRealtimeGrouping.groupingIntervals
      } else {
        if (lastKey === 'custom') {
          this.historicalSettingType = 'absolute'

          this.range = _.merge(
            {},
            {
              start: moment(startDate).toDate(),
              end: moment(endDate).toDate()
            }
          )

          this.setCustomGroupingInterval()
        } else {
          this.selectedHistoricalGrouping = _.find(
            this.historicalGroupingData,
            (grouping) => {
              return grouping.key === lastKey
            }
          )
          this.selectedHistoricalInterval = _.find(
            this.selectedHistoricalGrouping.groupingIntervals,
            (interval) => {
              return interval.key === intervalKey
            }
          )
          this.groupIntervalHistorical = this.selectedHistoricalGrouping.groupingIntervals
        }
      }

      this.selectedSettings = filterMetadata.type
      this.selectedAggFunction = filterMetadata.aggregate_func
    },
    onSave () {
      let selectedGrouping =
        this.selectedSettings === 'realtime'
          ? this.selectedRealtimeGrouping
          : this.selectedHistoricalGrouping
      let selectedInterval =
        this.selectedSettings === 'realtime'
          ? this.selectedRealtimeInterval
          : this.selectedHistoricalInterval

      let todayDate = moment()
      let endDate = null
      let startDate = null

      if (
        this.selectedSettings === 'historical' &&
        this.historicalSettingType === 'absolute'
      ) {
        endDate = moment(this.range.end).format('x')
        startDate = moment(this.range.start).format('x')
      } else {
        endDate = todayDate.format('x')
        startDate = todayDate
          .subtract(selectedGrouping.value, selectedGrouping.type)
          .format('x')
      }
      let params = {
        filter_metadata: {
          aggregate_func: this.selectedAggFunction,
          from_date: startDate,
          to_date: endDate,
          group_interval: selectedInterval.value,
          group_interval_type: selectedInterval.type.slice(0, -1),
          type: this.selectedSettings,
          last:
            this.selectedSettings === 'historical' &&
            this.historicalSettingType === 'absolute'
              ? 'custom'
              : selectedGrouping.key
        }
      }
      if (!this.isWidget) {
        // Save this settings to local storage, since it will be specific to the user viewing the exported dashboard
        let exportedDashboardKey =
          'exported_dashboard_' +
          this.selectedDashboard.uuid +
          '_filter_metadata'
        localStorage.setItem(
          exportedDashboardKey,
          JSON.stringify(params.filter_metadata)
        )      
        this.$emit('reload-panel')
      } else {
        this.$emit('set-realtime-historical-settings', {
          filterData: params.filter_metadata,
          widgetId: this.widgetId
        })
      }
    },
    onSelectRealtimeGrouping (grouping) {
      this.selectedRealtimeInterval = grouping.groupingIntervals[0]
      this.groupInterval = grouping.groupingIntervals
    },
    onSelectHistoricalGrouping (grouping) {
      if (this.isConsumptionTable || this.isConsumptionBarChart) {
        let historicalGrouping = grouping.groupingIntervals
        if (historicalGrouping[0].name !== 'no') {
          historicalGrouping.unshift({ name: 'no', key: '0_no', value: 0, type: 'noo' })
        }
        this.groupIntervalHistorical = historicalGrouping
        this.selectedHistoricalInterval = historicalGrouping[0]
      } else {
        this.selectedHistoricalInterval = grouping.groupingIntervals[0]
        this.groupIntervalHistorical = grouping.groupingIntervals
        // if (this.groupIntervalHistorical[0].name === 'no') {
        //   this.groupIntervalHistorical.shift()
        //   this.selectedHistoricalInterval = this.groupIntervalHistorical[0]
        // }
      }
    },
    initData () {
      this.realtimeGroupingData = _.take(groupingData, 13)
      this.selectedRealtimeGrouping = this.realtimeGroupingData[12]
      this.selectedRealtimeInterval = this.selectedRealtimeGrouping.groupingIntervals[0]
      this.groupInterval = this.selectedRealtimeGrouping.groupingIntervals
      this.historicalGroupingData = groupingData
      this.selectedHistoricalGrouping = this.historicalGroupingData[18]
      if (this.widgetData) {
        this.entityType = this.widgetData.entityType
        this.widgetId = this.widgetData.widgetId
      } else {
        this.entityType = 'panel'
        this.widgetId = null
      }
      if (this.isConsumptionTable || this.isConsumptionBarChart) {
        let historicalGrouping = this.selectedHistoricalGrouping.groupingIntervals
        if (historicalGrouping[0].name !== 'no') {
          historicalGrouping.unshift({ name: 'no', key: '0_no', value: 0, type: 'noo' })
        }
        this.groupIntervalHistorical = historicalGrouping
        this.selectedHistoricalInterval = historicalGrouping[0]
      } else {
        this.selectedHistoricalInterval = this.selectedHistoricalGrouping.groupingIntervals[0]
        this.groupIntervalHistorical = this.selectedHistoricalGrouping.groupingIntervals
        if (this.groupIntervalHistorical[0].name === 'no') {
          this.groupIntervalHistorical.shift()
          this.selectedHistoricalInterval = this.groupIntervalHistorical[0]
        }
      }
      this.selectedAggFunction =
        this.aggregationFunctions.length > 1
          ? this.aggregationFunctions[2]
          : this.aggregationFunctions[0]
      if (this.widgetData) {
        this.open(this.widgetData.widgetData)
      } else {
        this.open()
      }
    }
  },
  computed: {
    minDate () {
      return new Date(this.range.start)
    },
    maxDate () {
      return new Date(this.range.end)
    },
    isWidget () {
      return this.entityType === 'widget'
    },
    getType () {
      return this.widgetData.widgetData.type
    },
    openSection () {
      return this.entityType === 'widget'
        ? this.widgetData.widgetData.type
        : this.selectedPanel.filter_metadata.type
    },
    isConsumptionTable () {
      return this.widgetData && this.widgetData.widgetCategory[1] === 'table_consumption'
    },
    isConsumptionBarChart () {
      return this.widgetData && this.widgetData.widgetCategory[1] === 'bar_chart'
    }
  },
  // unmount () {
  //   this.$store.state.menu.customizer = ''
  //   this.$store.dispatch('layout/openRightSideBar', false)
  // },
  watch: {
    selectedHistoricalGrouping () {
      let timeFrame = ['3_month', '6_month', '1_year']
      if (_.includes(timeFrame, this.selectedHistoricalGrouping.key)) {
        this.selectedAggFunction = 'max'
        this.aggregationFunctions = this.withoutNoAgg
      } else {
        this.aggregationFunctions = this.withNoAgg
        }
    },
    selectedRealtimeGrouping () {
      this.aggregationFunctions = this.withNoAgg
    },
    selectedSettings () {
      let timeFrame = ['3_month', '6_month', '1_year']
      if (this.selectedSettings === 'historical' && _.includes(timeFrame, this.selectedHistoricalGrouping.key)) {
        this.selectedAggFunction = 'max'
        this.aggregationFunctions = this.withoutNoAgg
      } else {
        this.aggregationFunctions = this.withNoAgg
      }
    },
    range: {
      handler () {
        let start = moment(this.range.start)
        let end = moment(this.range.end)
        if (start.isValid() && end.isValid()) {
          let diff = end.diff(start, 'days')
          if(diff > 30) {
            this.selectedAggFunction = 'max'
            this.aggregationFunctions = this.withoutNoAgg
          } else {
            this.aggregationFunctions = this.withNoAgg
          }
        } else {
          this.range.start = moment().startOf('month')
          this.range.end = moment().endOf('month')
        }
      },
      deep: true
    }
  },
  mounted () {
    // this.$store.state.menu.customizer = 'layouts'
    this.range.start = moment().startOf('month')
    this.range.end = moment().endOf('month')
    this.initData()
    this.selectedSettings = this.entityType === 'widget'
      ? this.widgetData.widgetData.type
      : this.selectedPanel.filter_metadata.type
  }
}
</script>

<style lang="scss" scoped>
.setting-options-group {
  display: flex;
  background-color: #f8f9fa;
  .option {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-left: none;
    cursor: pointer;
    &.selected {
      border-bottom: 3px solid #007bff;
    }
    &:first-of-type {
      border-right: none;
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
      border-left: none;
      border-radius: 0 4px 4px 0;
    }
  }
}
.historical-type {
  .custom-radio {
    width: 48%;
    display: inline-block;
  }
}
.range-wrap {
  display: flex;
  justify-content: space-between;
  .form-group {
    width: 50%;
    input {
      width: calc(100% - 5px);
    }
  }
}
.main-content {
  padding: 0px 16px;
}
</style>
