import dashboardService from '../../../services/dashboard.service'

export default {
  namespaced: true,
  state: {
    selectedDashboard: null,
    selectedPanel: null,
    allDashboards: []
  },
  getters: {
    selectedDashboard: state => state.selectedDashboard,
    selectedPanel: state => state.selectedPanel,
    allDashboards: state => state.allDashboards
  },
  mutations: {
    SET_DASHBOARD (state, payload) {
      state.selectedDashboard = payload
    },
    SET_PANEL (state, payload) {
      state.selectedPanel = payload
    },
    SET_ALL_DASHBOARDs (state, payload) {
      state.allDashboards = payload
    }
  },
  actions: {
    async setDashboard ({ commit }, dashboard) {
      commit('SET_DASHBOARD', dashboard)
    },
    async setPanel ({ commit }, panel) {
      commit('SET_PANEL', panel)
    },
    async fetchdashboard ({ commit }, { config, token }) {
      dashboardService.loadDashboard(config, token)
        .then((response) => {
          commit('SET_DASHBOARD', response)
        }, (error) => {
          console.log('error', error)
        })
    },
    async fetchDashboardPanel ({ commit }, { config, token }) {
      dashboardService.loadDashboardPanel(config, token)
        .then((panel) => {
          commit('SET_PANEL', panel)
        }, (error) => {
          console.log('error', error)
        })
    },
    async fetchAllDashboard ({ commit }, { config, token }) {
      dashboardService.loadDashboards(config, token)
        .then((res) => {
          commit('SET_ALL_DASHBOARDs', res.resources)
        }, (error) => {
          console.log('error', error)
        })
    },
    async searchDashboard ({ commit }, { config, token, label }) {
      dashboardService.searchDashboards(config, token, label)
        .then((res) => {
          commit('SET_ALL_DASHBOARDs', res.resources)
        }, (error) => {
          console.log('error', error)
        })
    }
  }
}
