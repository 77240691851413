<template>
    <div class="container-fluid h-100 mx-md-0 px-md-0">
        <div class="row h-100">
            <div class="d-none d-md-block col-md-8 image-box pl-0 pr-0">
            
            </div>
            <div class="col-12 col-md-4 mx-md-0 mx-3 d-flex flex-column justify-content-center content-box">
                <div class="row my-3 mx-md-0 mx-2">
                    <div class="d-flex justify-content-center">
                        <div class="fs-md-4 fs-6 text-justify">
                            <span>Thingsbeam</span>
                        </div>
                    </div>
                </div>
                <div class="row ml-0 mt-3 input-box w-100 justify-content-center flex-grow-1">
                    <form class="needs-validation w-100" @submit.prevent="handleLogin">
                        <h2>Sign In</h2>
                        <div class="mb-4">
                            <label class="form-label mb-0">Email:</label>
                            <div class="input-group">
                                <span class="input-group-text"><svg-icon type="mdi" :path="email_svg"></svg-icon></span>
                                <input type="text" class="form-control" id="email" placeholder="Email address" v-model="email" required>
                            </div>
                            <div v-if="emailError" class="mt-1 text-danger">{{ emailError }}</div>
                        </div>
                        <div class="mb-4">
                            <label class="form-label mb-0">Password:</label>
                            <div class="input-group">
                                <span class="input-group-text"><svg-icon type="mdi" :path="pw_svg"></svg-icon></span>
                                <input type="password" class="form-control" id="password" placeholder="Password" v-model="password" required>
                            </div>
                        </div>
                        <div class="btn-toolbar mb-4 justify-content-between align-items-center">
                            <div class="form-check">
                                <div class="checkbox">
                                    <input class="form-check-input" id="invalidCheck" type="checkbox">
                                    <label class="form-check-label" for="invalidCheck">Remember</label>
                                </div>
                            </div>
                            <div class="signup-text">
                            <span>
                                <router-link to="/reset-password">
                                    Forgot Password?
                                </router-link>
                            </span>
                            </div>
                        </div>
                        <div class="submit mb-3">
                            <button class="btn btn-primary w-100" >Login</button>
                        </div>
                        <div class="signup-text text-center">
                            Don't have an account yet?
                            <span>
                                <router-link to="/sign-up">
                                    Sign Up
                                </router-link>
                            </span>
                        </div>
                    </form>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiEmail, mdiLock } from '@mdi/js';
import AuthService from '../services/auth.service.js'
import emailValidator from 'email-validator';


export default {
  name: 'Login',
  components: {
    SvgIcon
  },
  data() {
    return {
        email: "",
        password: "",
        message: "",
        email_svg: mdiEmail,
        pw_svg: mdiLock,
        emailError: "",
    }
  },
  methods: {
    handleLogin() {
        this.emailError = "";
        this.verifyEmail(this.email);
        if (!this.emailError) {
            AuthService.login(this.email, this.password);
        }
        
    },
    verifyEmail(email) {
        if (!emailValidator.validate(email)) {
            this.emailError = "Please enter a valid email address."
        }
    },
  }
}
</script>

<style scoped>
.login-bg {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}
.logo {
    display: block;
    max-width: 50%;
    height: auto;
}
.sublogo {
    display: block;
    max-width: 25%;
    height: auto;
}
</style>