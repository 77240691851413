<template>
    <div class="container-fluid h-100 mx-md-0 px-md-0">
        <div class="row h-100">
            <div class="d-none d-md-block col-md-8 image-box pl-0 pr-0">
                <img src="/assets/images/login_main.png" alt="main" class="login-bg">
            </div>
            <div class="col-12 col-md-4 mx-md-0 mx-3 d-flex flex-column justify-content-center content-box">
                <div class="row">
                    <div class="container d-flex justify-content-center align-items-center">
                        <img src="/assets/images/vft_logo.png" alt="Logo" class="logo">
                        <img src="/assets/images/vft_tagline.png" alt="sublogo" class="sublogo">
                    </div>
                </div>
                <div class="row m-0 input-box w-100 justify-content-center flex-grow-1">
                    <form class="needs-validation w-100" @submit.prevent="handleReset">
                        <h2>Reset Password</h2>
                        <div class="mb-4">
                            <label class="form-label mb-0">Email address:</label>
                            <div class="input-group">
                                <span class="input-group-text" id="inputGroupPrepend">@</span>
                                <input type="text" class="form-control" id="email" placeholder="Email address" v-model="email" required>
                            </div>
                            <div v-if="emailError" class="mt-1 text-danger">{{ emailError }}</div>
                        </div>
                        <div class="submit mb-3">
                            <button class="btn btn-primary w-100" >Send Email</button>
                        </div>
                        <div class="signup-text text-center">
                            Remember password?
                            <span>
                                <router-link to="/login">
                                    Login
                                </router-link>
                            </span>
                        </div>
                    </form>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '../services/auth.service.js'
import emailValidator from 'email-validator';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });
import * as Sentry from "@sentry/vue"

export default {
  name: 'ResetPassword',
  data() {
    return {
        email: "",
        emailError: "",
    }
  },
  methods: {
    handleReset() {
        this.emailError = "";
        this.verifyEmail(this.email);
        if (!this.emailError) {
            AuthService.forgotPassword({email: this.email})
            .then(() => {
                this.$router.push('/login')
                toaster.success('Reset link sent to your registered mail id')
            })
            .catch((error) => {
                if (error.request) {
                    let errorResponse = JSON.parse(error.request.response)
                    toaster.error(errorResponse.title);
                    const fetchError = new Error(`Reset password failed`);
                    fetchError.cause = error
                    Sentry.captureException(fetchError);
                } else {
                    toaster.error(error.title);
                    const fetchError = new Error(`Reset password failed`);
                    fetchError.cause = error
                    Sentry.captureException(fetchError);
                }
            })
        }
    },
    verifyEmail(email) {
        if (!emailValidator.validate(email)) {
            this.emailError = "Please enter a valid email address."
        }
    },
  }
}
</script>

<style scoped>
.login-bg {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}
.logo {
    display: block;
    max-width: 50%;
    height: auto;
}
.sublogo {
    display: block;
    max-width: 25%;
    height: auto;
}
</style>