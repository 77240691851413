<template>
  <router-link to="/dashboards">
    <img
      class="img-fluid for-light"
      src="../../assets/images/logo/vft_logo.png"
      alt=""
    /><img
      class="img-fluid for-dark"
      src="../../assets/images/logo/vft_logo.png"
      alt=""
    />
  </router-link>
</template>
<script>

  export default {
    name: 'Logo',
  };
</script>

<style lang="scss" scoped>
img {
  max-width: 80% !important;
}
</style>