<template>
  <div>
    <div class="customizer-contain" :class="{ open: customizer }">
      <div class="tab-content h-100">
        <CustomizerConfiguration :title="headerTitle" />
        <div class="customizer-body custom-scrollbar custome h-100">
          <div class="contentBody">
            <slot name="content"></slot>
          </div>
          <div class="w-100">
            <div class="d-flex justify-content-between">
              <button class="btn btn-light" @click="discardTextVal === 'Back' ? onBack() : onCancel()">
                <span>{{discardTextVal}}</span>
              </button>
              <button class="btn btn-primary" @click="applyTextVal === 'Apply Changes' ? onSave() : onNext()" :class="{'active': !applyTextVal}">
                <span>{{applyTextVal}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import CustomizerConfiguration from './customizerconfiguration';
  

export default {
  name: 'customizer',
  components : {
    CustomizerConfiguration,
  },
  props: {
    openSidebar: {
      type: Boolean,
      default: false
    },
    headerTitle: {
      type: String,
      required: true
    },
    discardText: {
      type: String
    },
    applyText: {
      type: String
    }
  },
  data () {
    return {
      applyTextBool: true,
      discardTextBool: true
    }
  },
  methods: {
    closeSidebar () {
      this.$store.state.menu.customizer = '';
      this.$store.dispatch('layout/openRightSideBar', false)
      this.$emit('close-sidebar')
    },
    onCancel () {
      this.$store.state.menu.customizer = '';
      this.$store.dispatch('layout/openRightSideBar', false)
      this.$emit('on-cancel')
    },
    onSave () {
      this.$store.state.menu.customizer = '';
      this.$store.dispatch('layout/openRightSideBar', false)
      this.$emit('on-save')
    },
    onNext () {
      this.$store.state.menu.customizer = '';
      this.$store.dispatch('layout/openRightSideBar', false)
      this.$emit('on-next')
    },
    onBack () {
      this.$emit('on-back')
    }
  },
  computed: {
    ...mapState({
      customizer: (state) => state.menu.customizer,
    }),
    discardTextVal () {
      return this.discardText ? this.discardText : ''
    },
    applyTextVal () {
      return this.applyText ? this.applyText : ''
    }
  }
  };
</script>

<style lang="scss" scoped>
.custome {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  letter-spacing: 0.7px;
  text-transform: capitalize;
  color: rgba(82, 82, 108, 0.8);
  width: 100%;
}
.customizer-contain .customizer-body {
  padding: 15px 15px;
}
</style>
