import {createRouter, createWebHistory} from "vue-router"
import Body from '../components/body.vue';
import Default from '../pages/dashboard/defaultPage.vue';
import Login from '../pages/Login.vue';
import Signup from '../pages/Signup.vue';
import ResetPassword from '../pages/ResetPassword.vue';
import Organisations from '../pages/Organisations.vue';
import DashboardList from '../pages/list/list.vue';
import Detail from '../pages/detail/index.vue'
import AuthRequired from "../utils/AuthRequired";

const routes =[
    {
        path: '',
        component: Body,
        beforeEnter: AuthRequired,
        children: [
          {
            path: '',
            name: 'defaultRoot',
            component: Default,
            children: [
              {
                path: '/dashboards',
                component: DashboardList
              },
              {
                path: '/dashboards/:id',
                component: Detail
              }
            ]
          },
        ]
      },
      {
        path: '/login',
        name: 'login',
        component: Login,
      },
      {
        path: '/sign-up',
        name: 'signup',
        component: Signup
      },
      {
        path: '/reset-password',
        name: 'reset',
        component: ResetPassword
      },
      {
        path: '/org',
        name: 'organisations',
        component: Organisations,
        beforeEnter: AuthRequired,
      }
]
const router=createRouter({
    history: createWebHistory(),
    routes
})

export default router