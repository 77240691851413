import ApiService from './api.service'
import _ from 'lodash'

const resource = '/dashboard'
const appName = '/api/v1/org/'

const dashboardService = {
  loadDashboards: async function (config, token) {
    const requestData = {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
      url: `/api/v1/org/${config.orgId}/get_shared_resources?type=dashboard&page_size=100&page_number=0`,
    }

    const response = await ApiService.customRequest(requestData)
    return response.data
  },
  searchDashboards: async function (config, token, label) {
    const requestData = {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
      url: `/api/v1/org/${config.orgId}/get_shared_resources?type=dashboard&page_size=100&page_number=0&label=${label}`,
    }

    const response = await ApiService.customRequest(requestData)
    return response.data
  },
  loadDashboard: async function (config, token) {
    const requestData = {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
      url: appName + config.orgId + resource + '/' + config.id,
    }
    const response = await ApiService.customRequest(requestData)
    return response.data
  },
  loadDashboardPanel: async function (config, token) {
    const requestData = {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
      url:appName + config.orgId + resource + '/' + config.dashboardId + '/panels/' + config.id,
    }
    const response = await ApiService.customRequest(requestData)
    return response.data
  },
  readWidgetInstance: async function (config, payload, token, tableParams) {
    let url = appName + config.orgId + '/dashboard/'+ config.dashboardId + '/panels' + '/' + config.panelId + '/widgets/' + config.widgetId + '/widget_instances/' + config.id
    if (!_.isEmpty(payload)) {
      let queryParams = `?aggregate_func=${payload.aggregate_func}&from_date=${payload.from_date}&to_date=${payload.to_date}&group_interval=${payload.group_interval}&group_interval_type=${payload.group_interval_type}&type=${payload.type}&last=${payload.last}`
      if (!_.isEmpty(tableParams)) {
        if (tableParams.isDynamicTable) {
          queryParams = `?aggregate_func=${payload.aggregate_func}&from_date=${payload.from_date}&to_date=${payload.to_date}&group_interval=${payload.group_interval}&group_interval_type=${payload.group_interval_type}&type=${payload.type}&last=${payload.last}&page_no=${tableParams.pageNo}&page_size=${tableParams.pageSize}${tableParams.paramerStr}${tableParams.sensorStr}&sort_dir=${tableParams.tableSorting}&sort_by=inserted_timestamp`
        } else {
          queryParams = `?aggregate_func=${payload.aggregate_func}&from_date=${payload.from_date}&to_date=${payload.to_date}&group_interval=${payload.group_interval}&group_interval_type=${payload.group_interval_type}&type=${payload.type}&last=${payload.last}${tableParams.paramerStr}${tableParams.sensorStr}&sort_dir=${tableParams.tableSorting}`
        }
      } else {
        queryParams = `?aggregate_func=${payload.aggregate_func}&from_date=${payload.from_date}&to_date=${payload.to_date}&group_interval=${payload.group_interval}&group_interval_type=${payload.group_interval_type}&type=${payload.type}&last=${payload.last}`
      }
      url = url + queryParams
    }
    const requestData = {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
      url: url,
    }
    const response = await ApiService.customRequest(requestData)
    console.log(url, response.data)
    return response.data
  },
  confirmEmail: async function (token) {
    const response = await ApiService.put('/api/v1' + '/confirm-email?token='+ token)
    return response.data
  },
  forgotPassword: async function (payload) {
    const response = await ApiService.post('/api/v1' + '/forgot-password', payload)
    return response.data
  },
  resetPassword: async function (token, payload) {
    const response = await ApiService.put('/reset_password?token='+ token, payload)
    return response.data
  },
  tablesensorChange: async function (config, params, filter, token) {
    let url = null
    if (params.isDynamicTable) {
      url =  `${appName}/${config.orgId}/dashboard/${config.dashboardId}/panels/${params.panelId}/widgets/${params.widgetId}/widget_instances/${params.widgetInstanceId}?aggregate_func=${filter.aggregate_func}&from_date=${filter.from_date}&to_date=${filter.to_date}&group_interval=${filter.group_interval}&group_interval_type=${filter.group_interval_type}&type=${filter.type}&last=${filter.last}&page_no=${params.pageNo}&page_size=${params.pageSize}${params.paramerStr}${params.sensorStr}&sort_dir=${params.tableSorting}&sort_by=inserted_timestamp`
    } else {
      url =  `${appName}/${config.orgId}/dashboard/${config.dashboardId}/panels/${params.panelId}/widgets/${params.widgetId}/widget_instances/${params.widgetInstanceId}?aggregate_func=${filter.aggregate_func}&from_date=${filter.from_date}&to_date=${filter.to_date}&group_interval=${filter.group_interval}&group_interval_type=${filter.group_interval_type}&type=${filter.type}&last=${filter.last}${params.paramerStr}${params.sensorStr}&sort_dir=${params.tableSorting}`
    }
    const requestData = {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
      url: url
    }
    const response = await ApiService.customRequest(requestData)
    return response.data
  },
  onExportData: async function (config, params, token) {
    let url = null
    if (params.isDynamicTable) {
      url = `${appName}${config.orgId}/export_dynamic_table?from_date=${params.startDate}&to_date=${params.endDate}${params.parameter_ids}${params.sensor_ids}&sort_dir=${params.sort_dir}&sort_by=inserted_timestamp&export_medium=${params.export_medium}${params.emails}&time_range=${params.time_range}&widget_instance_id=${params.widget_instance_id}`
    } else {
      url = `${appName}${config.orgId}/export_consumption_table?from_date=${params.startDate}&to_date=${params.endDate}${params.parameter_ids}${params.sensor_ids}&sort_dir=${params.sort_dir}&export_medium=${params.export_medium}${params.emails}&time_range=${params.time_range}&widget_instance_id=${params.widget_instance_id}`
    }
    const requestData = {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
      url: url
    }

    const response = await ApiService.customRequest(requestData)
    return response.data
  }
}

export default dashboardService
