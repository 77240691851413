import axios from 'axios'
import _ from 'lodash'
import TokenService from './token.service'
import router from '../router'

const ApiService = {

  // Stores the interceptor so that it can be later ejected when needed
  _interceptor: null,

  init (baseURL) {
    axios.defaults.baseURL = baseURL
  },

  setHeader (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
  },

  removeHeader () {
    axios.defaults.headers.common = {}
  },

  get (resource, data) {
    return axios.get(resource, data)
  },

  post (resource, data) {
    return axios.post(resource, data)
  },

  put (resource, data) {
    return axios.put(resource, data)
  },

  delete (resource, data) {
    return axios.delete(resource, data)
  },

  /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
  customRequest (data) {
    return axios(data)
  },

  mountInterceptor () {
    this._interceptor = axios.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        console.log('error :: ', error)
        let errorResponse = {}
        if (error.request.status === 403) {
          TokenService.removeToken()
          TokenService.removeRefreshToken()
          localStorage.clear()
          sessionStorage.clear()
          router.push('/')
        }
        if (error.request.status === 500) {
          errorResponse = {
            title: 'Please consult with your admin',
            details: 'Something went wrong, please consult with your admin',
            detail: 'Something went wrong, please consult with your admin',
            source: {}
          }
        } else {
          errorResponse = JSON.parse(error.request.response)
        }
        errorResponse = _.merge({}, errorResponse, { data: {} })
        throw errorResponse
      }
    )
  },

  unmount401Interceptor () {
    // Eject the interceptor
    axios.interceptors.response.eject(this._interceptor)
  }
}

export default ApiService;