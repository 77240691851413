<template>
    <div class="h-100 w-100">
      <div class="list-wrap">
        <div class="dashboards-table mt-3">
            <h3>Dashboard Listing</h3>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th><strong>Dashboard Name</strong></th>
                  <th><strong>Shared On</strong></th>
                  <th><strong>Shared By</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="dashboard, index in allDashboards" :key="index" @click="selectDashboard(dashboard)" class="tbl-row">
                  <th scope="row">{{index + 1}}</th>
                  <td>{{ dashboard.resource.name }}</td>
                  <td>{{ moment(dashboard.resource.shared_at).format("ddd, DD MMM") }}</td>
                  <td>{{ dashboard.share_by.first_name }}</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import { mapGetters, mapActions } from "vuex"
  import moment from "moment"
  export default {
    data() {
      return { }
    },
    methods: {
      ...mapActions({
        fetchAllDashboard: 'dashboard/fetchAllDashboard'
      }),
      moment: function (date) {
        return moment(date)
      },
      inNewTab(dashboard) {
        console.log("inNewTab :: ", dashboard)
      },
      selectDashboard(dashboard) {
        let id = dashboard.resource.id
        this.$router.push(`/dashboards/${id}`)
      },
      loadDashboards () {
        let token = sessionStorage.getItem('access_token')
        let config = { orgId: this.userOrg.id };
        this.fetchAllDashboard({config, token})
      }
    },
    mounted () {
      this.loadDashboards()
    },
    computed: {
      ...mapGetters({
        userOrg: "userOrg",
        allDashboards: 'dashboard/allDashboards'
      }),
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .dashboards-table {
    letter-spacing: 0.7px;
    text-transform: capitalize;
    color: rgba(82, 82, 108, 0.8);
  }
  .tbl-row {
    cursor: pointer;
  }
  </style>