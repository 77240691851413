/* eslint-disable no-unused-vars */
import _ from 'lodash'

export default {
  state: {
    currentUser: {
      org: {
        id: null
      }
    }
  },
  getters: {
    currentUser: state => state.currentUser
  },
  mutations: {
    setUserProfile (state, payload) {
      state.currentUser.org.id = payload
    }
  },
  actions: {
    async setUserProfile ({ commit, _ }, user) {
      commit('setUserProfile', user)
    }
  }
}
