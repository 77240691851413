<template>
    <div class="container-fluid h-100 mx-md-0 px-md-0">
        <div class="row h-100">
            <div class="d-none d-md-block col-md-8 image-box pl-0 pr-0">
            </div>
            <div class="col-12 col-md-4 mx-md-0 mx-3 d-flex flex-column justify-content-center content-box">
                <div class="row">
                </div>
                <div class="row m-0 input-box w-100 justify-content-center flex-grow-1">
                    <form class="needs-validation w-100" @submit.prevent="signUp">
                        <h2>Sign Up</h2>
                        <div class="mb-2">
                            <label class="form-label mb-0">First name:</label>
                            <div class="input-group">
                                <span class="input-group-text" id="inputGroupPrepend">@</span>
                                <input type="text" class="form-control" id="name" placeholder="Your name" v-model="name" required>
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="form-label mb-0">Email address:</label>
                            <div class="input-group">
                                <span class="input-group-text">@</span>
                                <input type="text" class="form-control" id="email" placeholder="Email address" v-model="email" required>
                            </div>
                            <div v-if="emailError" class="mt-1 text-danger">{{ emailError }}</div>
                        </div>
                        <div class="mb-2">
                            <label class="form-label mb-0">Password:</label>
                            <div class="input-group">
                                <span class="input-group-text">@</span>
                                <input type="password" @keyup="verifyPassword" class="form-control" id="password" placeholder="Password" v-model="password" required>
                            </div>
                            <div v-if="passwordError" class="mt-1 text-danger">{{ passwordError }}</div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label mb-0">Repeat Password:</label>
                            <div class="input-group">
                                <span class="input-group-text">@</span>
                                <input type="password" @keyup="verifyPassword2" class="form-control" id="password2" placeholder="Password" v-model="verify" required>
                            </div>
                            <div v-if="passwordError2" class="mt-1 text-danger">{{ passwordError2 }}</div>
                        </div>
                        <div class="signup-text mb-3 text-center">
                            Already have an account?
                            <span>
                                <router-link to="/login">
                                    Log in
                                </router-link>
                            </span>
                        </div>
                        <div v-if="message">{{ message }}</div>
                        <div class="submit">
                            <button class="btn btn-primary w-100" >Sign Up</button>
                        </div>
                    </form>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '../services/auth.service.js'
import emailValidator from 'email-validator';
import * as Sentry from "@sentry/vue"

export default {
  name: 'Signup',
  data() {
    return {
        email: "",
        emailError: "",
        password: "",
        passwordError: "",
        passwordError2: "",
        verify: "",
        name: "",
        message: "" 
        }
    },
    watch: {
        password() {
        this.validatePasswords();
        },
        verify() {
        this.validatePasswords();
        }
    },
  methods: {
    verifyPassword() {
        function verifyInput(input) {
            const uppercaseRegex = /[A-Z]/;
            const lowercaseRegex = /[a-z]/;
            const specialCharRegex = /[@#$!%^*?&()_|><+:;,/.*]/;
            return (
                uppercaseRegex.test(input) &&
                lowercaseRegex.test(input) &&
                specialCharRegex.test(input)
            );
        }
        if ((this.password.length < 8) || !verifyInput(this.password)) {
            this.passwordError = "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (@#$!%^*?&()_|><+:;,/.*)"
        } else {
            this.passwordError = ''
        }
    },
    validatePasswords() {
        if (this.password !== this.verify) {
            this.passwordError2 = "Password and confirm password must be same"
        } else {
            this.passwordError2 = "";
        }
    },
    verifyEmail(email) {
        if (!emailValidator.validate(email)) {
            this.emailError = "Please enter a valid email address."
        }
    },
    signUp() {
        this.emailError = "";
        this.verifyEmail(this.email);
        if (!this.emailError && !this.passwordError && !this.passwordError2) {
            AuthService.registerUser({
                "email": this.email,
                "password": this.password,
                "first_name": this.name
            })
            .then(() => {
                this.message = "Sign up successful. You will receive a confirmation email when the admin approves.";  
            })
            .catch((e) => {
                // Sentry.captureMessage("Sign up failed", {extra: e.title})
                const fetchError = new Error(`Sign up failed`);
                fetchError.cause = e;
                Sentry.captureException(fetchError);
                this.message = "Sign up failed. Please try again.";
            })
        }
    }
  }
}

</script>

<style scoped>
.login-bg {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}
.logo {
    display: block;
    max-width: 50%;
    height: auto;
}
.sublogo {
    display: block;
    max-width: 25%;
    height: auto;
}

.welcome-text {
    font-weight: 500;
    font-size: 20px;
    color: #212429
}
</style>