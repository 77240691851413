<template>
    <div class="h-100">
      <container
        v-if="selectedPanel"
        :selectedDashboard="selectedDashboard"
        :selectedPanel="selectedPanel"
        :dashboardType="'viewable'"
        :layoutType="dashboardLayoutType"
      ></container>
      <!-- <export-right-panel
        v-if="typeOfPanel === 'exportRightPanel'"
        :exportData="exportData"
        @close-right-panel="closeSidebar"
      >
      </export-right-panel> -->
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex'
  import dashboardService from '../../services/dashboard.service'
  import container from '../container/index.vue'
//   import exportRightPanel from '@/components/blankTemplate/exportRightPanel.vue'
  import _ from 'lodash'
  
  export default {
    name: 'dashboard-detail',
    components: {
        container,
        // exportRightPanel
    },
    provide () {
      return {
        loadDashboardPanelFn: this.loadDashboardPanel,
        loadWidgetInstanceDataFn: this.loadWidgetInstanceData,
        exitDashboardFn: this.exitDashboard,
        tablesensorChangeFn: this.tablesensorChange,
        exportDataFn: this.exportDatafn,
        onExportDataFn: this.onExportData
      }
    },
    data () {
      return {
        screenWidth: window.screen.availWidth,
        dashboardLayoutType: 'laptop',
        selectedDashboardId: null,
        typeOfPanel: null
      }
    },
    methods: {
      ...mapActions({
        fetchdashboard: 'dashboard/fetchdashboard',
        fetchDashboardPanel: 'dashboard/fetchDashboardPanel',
        setDashboard: 'dashboard/setDashboard',
        setPanel: 'dashboard/setPanel'
      }),
      findDeviceForDashboardLayout () {
        // tv - 1920 and above
        // laptop - below 1920
        // tablet - below 1366
        // phone - below 480
  
        if (this.screenWidth < 480) {
          this.dashboardLayoutType = 'phone'
        } else if (this.screenWidth >= 480 && this.screenWidth < 800) {
          this.dashboardLayoutType = 'tablet'
        } else if (this.screenWidth >= 800 && this.screenWidth < 1920) {
          this.dashboardLayoutType = 'laptop'
        } else if (this.screenWidth >= 1920) {
          this.dashboardLayoutType = 'tv'
        }
      },
      loadDashboard () {
        let token = sessionStorage.getItem('access_token')
        let config = { orgId: this.userOrg.id, id: this.selectedDashboardId }
        this.fetchdashboard({config, token})
      },
      // Provider Functions
      loadDashboardPanel (id) {
        let token = sessionStorage.getItem('access_token')
        let config = { orgId: this.userOrg.id, dashboardId: this.selectedDashboardId, id: id }
  
        this.fetchDashboardPanel({ config, token })
      },
      loadWidgetInstanceData ({ params, widgetId, id, tableParams }) {
        let token = sessionStorage.getItem('access_token')
        let config = { orgId: this.userOrg.id, dashboardId: this.selectedDashboardId, panelId: this.selectedPanel.id, widgetId: widgetId, id: id }
        return dashboardService.readWidgetInstance(config, params, token, tableParams)
      },
      tablesensorChange ({ params, filter }) {
        let config = { orgId: this.userOrg.id, dashboardId: this.selectedDashboardId }
        let token = sessionStorage.getItem('access_token')
        return dashboardService.tablesensorChange(config, params, filter, token)
      },
      exportDatafn (data) {
        this.exportData = _.merge({}, data)
        this.typeOfPanel = 'exportRightPanel'
      },
      onExportData ({ params }) {
        let config = { orgId: this.userOrg.id }
        let token = sessionStorage.getItem('access_token')
        return dashboardService.onExportData(config, params, token)
      },
      closeSidebar () {
        this.typeOfPanel = null
      },
      exitDashboard () {
        this.$router.push('/dashboards')
        this.setDashboard(null)
        this.setPanel(null)
      }
    },
    watch: {
      selectedDashboard () {
        let panelId = null
  
        if(this.selectedDashboard) {
          let panelsOrder = this.selectedDashboard.settings.panels_order
          if (panelsOrder) {
            _.forEach(panelsOrder, (order, id) => {
              if (order === '1') {
                panelId = id
              }
            })
          } else {
            panelId = this.selectedDashboard.panels[0].id
          }
          console.log('Panel ID =',panelId)
          this.loadDashboardPanel(panelId)
        }
      }
    },
    computed: {
      ...mapGetters({
        selectedDashboard: 'dashboard/selectedDashboard',
        selectedPanel: 'dashboard/selectedPanel',
        userOrg: 'userOrg'
      })
    },
    mounted () {
      this.selectedDashboardId = this.$route.params.id
      this.findDeviceForDashboardLayout()
      this.loadDashboard()
    }
  }
  </script>
  