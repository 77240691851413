<template>
    <div class="main-container h-100">
        <div class="container-fluid" v-if="showOrgs">
            <div class="row">
                <div class="col-12 d-flex justify-content-center align-items-center mt-3">
                    <img src="/assets/images/vft_logo.png" alt="Logo" class="logo">
                    <div class="icon-container" @click="logOut()">
                        <svg-icon class="icon" type="mdi" :path="logout_svg"></svg-icon>
                        <span>Log Out</span>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-flex justify-content-center">
                    <div class="welcome-text">
                        Welcome to
                        <span>Energy Cloud</span>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 d-flex justify-content-center">
                    <div class="select-text">
                        Select Organization
                    </div>
                </div>
            </div>
            <div class="orgs-wrap my-0" v-if="showOrgs">
                <ul>
                    <li v-for="org in organizations" @click="redirDashList(org)" :key="org.id">
                        <img :src="org.avatar" />
                        <span>{{org.name}}</span>
                    </li>           
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../store';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiLogout } from '@mdi/js';
import { mapActions } from 'vuex';

export default {
  name: 'Organsations',
  components: {
    SvgIcon
  },
  data()  {
    return {
        dash_id: null,
        showOrgs: true,
        dashList: null,
        organizations: JSON.parse(localStorage.getItem('organisations')),
        logout_svg: mdiLogout
    }
  },
  methods: {
    ...mapActions([
        'orgLoginSucess',
        'setUserOrg'
    ]),
    logOut() {
        store.dispatch('logout');
    },
    redirDashList(org) {
        this.setUserOrg(org)
        this.orgLoginSucess()
    }
  }
}
</script>

<style scoped>
.main-container {
    background-color: #f7f8ff;
    background-size: cover
}
.logo {
    display: block;
    margin-left: 44.5%;
    margin-right: auto;
    max-width: 10%;
    height: auto;
}
.welcome-text {
    font-weight: 500;
    font-size: 40px;
    line-height: 65px;
    color: #212429
}

.welcome-text span {
    font-weight: 600
}

.select-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #212429
}

.org-logo {
    cursor: pointer
}

.orgs-wrap {
    display: flex;
    justify-content: center
}

.orgs-wrap ul {
    padding: 0 20%;
    min-height: 300px;
    max-height: 450px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none
}

.orgs-wrap ul>li {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 17px 12px;
    cursor: pointer
}

.orgs-wrap img {
    width: 130px;
    height: 136px;
    border-radius: 4px;
    background: #fff;
    padding: 12px
}

.orgs-wrap span {
    padding-top: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center
}

.icon-container {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 8px 24px;
    gap: 8px;
    background: rgba(0,0,0,.15);
    border-radius: 50px;
    cursor: pointer
}

.icon-container .icon {
    width: 20px;
    height: 20px;
    fill: #fff;
    cursor: pointer
}

@media only screen and (max-width: 600px) {

    .welcome-text {
        font-size: 25px
    }

    .org-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin: 15px 1rem
    }
}
</style>
