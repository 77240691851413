<template>
    <div>
      <cloudTemplate
      ref="blankTemplate"
      v-if="selectedPanel"
      :selectedDashboard="selectedDashboard"
      :selectedPanel="selectedPanel"
      :layoutType="dashboardLayoutType"
      :dashboardType="'viewable'"
      :appType="'cockpit'"
      @change-layout-mode="changeLayoutMode"
    >
      <template v-slot:rightPanel="slotProps">
        <real-time-historical-right-panel
          v-if="slotProps.typeOfPanel === 'realtime'"
          :dashboardType="slotProps.dashboardType"
          :selectedDashboard="slotProps.selectedDashboard"
          :aggregationFunctions="slotProps.aggregationFunctions"
          :widgetData="slotProps.widgetData"
          :selectedPanel="slotProps.selectedPanel"
          :widgetCategory="slotProps.widgetCategory"
          @set-realtime-historical-settings="setRealTimeSetting"
          @close-right-panel="closeSidebar"
          @reload-panel="reloadPanel"
        ></real-time-historical-right-panel>
      </template>
    </cloudTemplate>
    </div>
  </template>
  
  <script>
  // import Loading from 'vue-loading-overlay'
  import html2canvas from 'html2canvas'
  import { saveAs } from 'file-saver'
  import { socketUrl } from '../../constants/config'
  import { Socket } from 'phoenix-socket'
  import realTimeHistoricalRightPanel from '../../components/realTimeHistoricalTimeSetting.vue'
  import _ from 'lodash'
import { mapGetters } from 'vuex'
  
  export default {
    components: {
      // Loading,
      realTimeHistoricalRightPanel,
    },
    props: {
      dashboards: {
        type: Array,
        default: () => {
          return []
        },
      },
      dashboardType: {
        type: String,
        default: 'editable',
      },
      layoutType: {
        type: String,
        default: 'laptop',
      },
      hideSidebar: {
        type: Boolean,
        default: false,
      },
      hideHeader: {
        type: Boolean,
        default: false,
      },
      defaultDashboardMode: {
        type: String,
        default: 'view',
      },
      dashboardSocketToken: {
        type: String,
        default: '',
      },
      selectedDashboard: {
        type: Object,
        required: true,
      },
      selectedPanel: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      currentUser: {
        default: null,
      },
      hideBackBtn: {
        type: Boolean,
        default: false,
      },
    },
    inject: {
      updateDashboardPanelFn: {
        default: () => {},
      },
      loadDashboardPanelFn: {
        default: () => {},
      },
    },
    data() {
      return {
        editMode: false,
        widgetObject: {},
        isEditingDashboard: false,
        layout: [],
        realTimeHistoricalSettings: {},
        realTimeHistoricalSelectedWidget: null,
        channel: null,
        fullPage: false,
        sidebarSize: 25,
        isMobileScreen: false,
        sidebarMode: 'expanded',
        sidebarVisible: true,
        typeOfPanel: null,
        aggregationFunctions: [],
        widgetData: null,
        screenWidth: window.screen.availWidth,
        dashboardLayoutType: 'laptop',
      }
    },
    methods: {
      openRightPanelSetting({ type, widgetData = null }) {
        this.typeOfPanel = type
        this.widgetData = widgetData
        this.aggregationFunctions =
          widgetData && widgetData.aggregators
            ? widgetData.aggregators
            : ['no', 'min', 'max', 'average', 'sum', 'count', 'max-min']
      },
      closeSidebar() {
        this.$refs.blankTemplate.closeSidebar()
      },
      changeSidebarMode(mode) {
        this.sidebarMode = mode
      },
      showSidebar(show) {
        this.sidebarVisible = show
      },
      toggleSidebar() {
        if (this.sidebarSize) {
          this.sidebarSize = 0
          document.getElementsByClassName(
            'splitpanes__splitter'
          )[0].style.display = 'none'
        } else {
          if (this.isMobileScreen) {
            this.sidebarSize = 100
          } else {
            this.sidebarSize = 25
          }
          document.getElementsByClassName(
            'splitpanes__splitter'
          )[0].style.display = 'block'
        }
      },
      getUniqueKey() {
        let result = ''
        let characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
        let charactersLength = characters.length
        for (var i = 0; i < 20; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          )
        }
        return result
      },
      onChangeMode(mode) {
        if (mode === 'edit') {
          this.editMode = true
        } else {
          this.editMode = false
        }
      },
      getCardBackgroundColor(item) {
        if (
          this.getVisualProperties(item).chart ||
          this.getVisualProperties(item).card
        ) {
          let widgetSettings =
            this.getVisualProperties(item).chart ||
            this.getVisualProperties(item).card
          return widgetSettings ? widgetSettings.backgroundColor : '#ffffff'
        } else {
          return this.getVisualProperties(item).background_and_position
            ? this.getVisualProperties(item).background_and_position.background
            : '#ffffff'
        }
      },
      getVisualProperties(item) {
        return this.widgetObject[item.i]
          ? this.widgetObject[item.i].visual_properties
          : {}
      },
      setPanelLayout(panel) {
        this.widgetObject = {}
        let layout = []
  
        _.forEach(panel.widgets || [], (widget) => {
          this.widgetObject[widget.id] = widget
        })
  
        const widgetLayouts =
          panel.widget_layouts[this.dashboardLayoutType] || panel.widget_layouts
        // const widgetLayouts = panel.widget_layouts
  
        _.forEach(widgetLayouts || {}, (setting, id) => {
          if (setting) {
            layout.push(
              _.merge(setting, { i: id, key: this.getUniqueKey() })
            )
          }
        })
  
        this.layout = _.filter(layout, (item) => {
          return this.widgetObject[item.i]
        })
      },
      onSaveDashboardPanel(exitEditMode = true) {
        if (exitEditMode) {
          this.editMode = false
        }
        let widgetLayouts = {}
  
        _.forEach(this.layout, (item) => {
          widgetLayouts[item.i] = {
            h: item.h,
            w: item.w,
            x: item.x,
            y: item.y,
            type: item.type,
          }
        })
  
        let payload = {
          widget_layouts: _.merge({}, this.selectedPanel.widget_layouts, {
            [this.dashboardLayoutType]: widgetLayouts,
          }),
          // widget_layouts: widgetLayouts
        }
  
        this.updateDashboardPanelFn({ payload, id: this.selectedPanel.id })
          .then(() => {
            this.$toastr.success('Panel saved', 'Success', {
              closeButton: true,
              closeDuration: 0,
            })
            this.loadDashboardPanelFn(this.selectedPanel.id)
          })
          .catch((error) => {
            this.$toastr.error(error.title, 'Error', {
              closeButton: true,
              closeDuration: 0,
            })
          })
      },
      onCancelEditDashboard() {
        this.editMode = false
      },
      savePanelRealtimeHistoricalSettings() {
        this.layout.forEach((item) => {
          let ref = 'widget' + this.getWidgetInstanceId(item)
          this.$refs[ref][0].savePanelRealtimeHistoricalSettings()
        })
      },
      getWidgetId(item) {
        return this.widgetObject[item.i].widget_id
      },
      getWidgetInstanceId(item) {
        return this.widgetObject[item.i].id
      },
      onDeleteWidgetInstance(id) {
        this.layout = _.filter(this.layout, (item) => {
          return parseInt(item.i) !== id
        })
      },
      captureDashboard() {
        let element = document.getElementById('screenshot-wrap')
  
        html2canvas(element).then(function (canvas) {
          // Export canvas as a blob
          canvas.toBlob(function (blob) {
            // Generate file download
            saveAs(blob, 'blank_template.png')
          })
        })
      },
      resizeHighchartWidget(item) {
        let ref = 'widget' + this.getWidgetInstanceId(item)
        this.$refs[ref][0].resizeWidget(item)
      },
  
      // Socket Code
  
      createWidgetSocketListener() {
        this.channel.on('create_widget_out_put_res', () => {
          // let widgetInstance = payload
          // let widgetLayouts = widgetInstance.panel.widget_layouts
          // delete widgetInstance.panel
          // let widgets = _.concat([], this.selectedPanel.widgets, widgetInstance)
  
          // const panel = _.merge({}, this.selectedPanel, {
          //   widgets: widgets,
          //   widget_layouts: widgetLayouts
          // })
  
          // this.setPanelLayout(panel)
          this.loadDashboardPanelFn(this.selectedPanel.id)
        })
      },
      createPanelChannel(panelId) {
        this.channel = this.socket.channel('panels:' + panelId, {})
        this.channel
          .join()
          .receive('ok', () => {})
          .receive('error', () => {})
        this.createWidgetSocketListener()
      },
      createSocketConnections() {
        this.socket = new Socket(socketUrl, {
          params: { token: this.dashboardSocketToken },
        })
        this.socket.connect()
        this.createPanelChannel(this.selectedPanel.id)
      },
      initWatchMedia() {
        const watchMedia = window.matchMedia('(max-width: 767px)')
        if (watchMedia.matches) {
          // If media query matches
          this.sidebarSize = 0
          document.getElementsByClassName(
            'splitpanes__splitter'
          )[0].style.display = 'none'
          this.isMobileScreen = true
        }
      },
      reloadPanel() {
        this.setPanelLayout(this.selectedPanel)
        this.closeSidebar()
      },
      changeLayoutMode (view) {
        this.dashboardLayoutType = view
      },
      setRealTimeSetting (data) {
        this.$refs.blankTemplate.setRealTimeSetting(data)
      }
    },
    watch: {
      selectedPanel() {
        if (this.selectedPanel) {
          this.setPanelLayout(this.selectedPanel)
        }
  
        if (this.channel) {
          this.channel.off()
        }
        if (this.dashboardSocketToken) {
          this.createPanelChannel(this.selectedPanel.id)
        }
      },
      layoutType () {
        this.dashboardLayoutType = this.layoutType
      },
      isRightSideBar () {
        if (this.isRightSideBar) {
          this.$refs.blankTemplate.openRightPanelSetting({'type': 'realtime'})
        }
      }
    },
    computed: {
      ...mapGetters({
        isRightSideBar: 'layout/isRightSideBar'
        })
    },
    mounted() {
      this.dashboardLayoutType = this.layoutType
      if (this.selectedPanel) {
        this.setPanelLayout(this.selectedPanel)
      }
  
      if (this.dashboardSocketToken) {
        this.createSocketConnections()
      }
      // this.initWatchMedia()
      if (this.dashboardLayoutType === 'phone' || this.dashboardLayoutType === 'tablet') {
        this.sidebarVisible = false
      }
  
  
      if (this.defaultDashboardMode === 'edit') {
        this.editMode = true
      }
    },
    created () {
      this.dashboardLayoutType = this.layoutType
    },
    beforeUnmount() {
      if (this.socket) {
        this.socket.disconnect()
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .dashboard-container {
    display: flex;
    height: 100%;
    position: relative;
    .sidebar-slider {
      position: absolute;
      left: 5px;
      top: 19px;
      cursor: pointer;
      .icon-wrap {
        background: black;
        padding: 5px;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          color: #ffffff !important;
          font-size: 15px;
        }
      }
      &.expand {
        transform: rotate(-180deg);
      }
    }
    .content-wrap {
      width: 100%;
      overflow: auto;
      background-color: #ffffff;
      position: relative;
      height: 100%;
      .widgets-wrap {
        width: 100%;
        height: calc(100% - 45px);
        overflow: auto;
        background-color: #f8f8f8;
        .layout-container {
          width: 100%;
          height: 100%;
          overflow: auto;
        }
        .vue-grid-layout {
          .vue-grid-item {
            background-color: white;
            box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
            overflow: hidden;
            touch-action: auto;
            border-radius: 4px;
            padding: 8px;
            &:hover {
              .header {
                .actions {
                  .icon {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .btn.back-btn {
    margin-bottom: 20px;
    width: 105px;
    height: 10px;
    display: flex;
    align-items: center;
  }
  </style>
  
  <style lang="scss">
  .select-wrap {
    label {
      text-transform: capitalize;
      font-size: 14px;
      color: #6d6e71;
    }
  }
  .hamburger-action {
    button {
      padding: 0 !important;
      background-color: transparent !important;
      .hamburger {
        width: 35px;
        height: 35px;
        fill: #000;
      }
    }
    .b-dropdown .dropdown-toggle::after {
      display: none !important;
    }
    .dropdown-item {
      padding: 5px 10px !important;
      font-size: 12px;
    }
    .dropdown-menu.show {
      left: -160px !important;
      top: 0px !important;
      height: 200px;
      overflow: auto;
    }
  }
  .splitpanes--vertical > .splitpanes__splitter {
    // border: 5px solid #2a3042 !important;
  }
  .vue-grid-item.vue-grid-placeholder {
    background: transparent !important;
  }
  </style>
  