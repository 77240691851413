 let menuItems = {
  "data": [
    {
      "headTitle1": "General",
      "headTitle2": "Dashboards,Widgets & Layout.",
      "type": "headtitle"
    },
    {
      "title": "Dashboard",
      "icon": "stroke-home",
      "iconf":"fill-home",
      "type": "link",
      "path": "dashboards",
      "badgeType": "light-primary",
      "badgeValue": 0,
      "active": false,
      "children": []
    }
  ]
}

export default menuItems