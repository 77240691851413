import store from '../store'

export default (to, from, next) => {
  let isUserLoggedIn = store.getters.isUserLoggedIn
  if (isUserLoggedIn) {
    next()
  } else {
    let url = (new URL(window.location.href)).pathname.split('/')
    let path = url.join('/')
    store.dispatch('validateToken', path)
  }
}
