<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img class="b-r-10" src="/src/assets/images/dashboard/profile.png" alt="" />
      <div class="media-body">
        <span>{{ firstName }} <i class="middle fa fa-angle-down"></i> </span>
        <!-- <p class="mb-0 font-roboto">
          Admin <i class="middle fa fa-angle-down"></i>
        </p> -->
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <!-- <li>
        <vue-feather type="user"></vue-feather><span>Account </span>
      </li>
      <li>
        <vue-feather type="mail"></vue-feather><span>Inbox</span>
      </li>
      <li>
        <vue-feather type="file-text"></vue-feather><span>Taskboard</span>
      </li>
      <li>
        <vue-feather type="settings"></vue-feather><span>Settings</span>
      </li> -->
      <li>
        <vue-feather type="log-in"></vue-feather><span @click="handleLogout">Log out</span>
      </li>
    </ul>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '../../store';

export default {
  name: 'Profile',
  data() {
    return {
      firstName: ''
    }
  },
  methods: {
    handleLogout: function () {
      this.$store.dispatch('menu/setMenuDataDefault')
      store.dispatch('logout')
    }
  },
  mounted () {
    this.userData ? this.firstName = this.userData.first_name : ''
  },
  watch: {
    userData () {
      this.firstName = this.userData.first_name
    }
  },
  computed: {
    ...mapGetters({
      userData: "userData",
      userOrg: "userOrg"
    }),
  }
};
</script>
